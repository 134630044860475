import React from 'react';
import { graphql } from 'gatsby';

import DocsLayout from '../components/docs-layout/index';

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { html } = markdownRemark;
  return (
    <DocsLayout>
      <div className="blog-post-container">
        <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </DocsLayout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
      }
    }
  }
`;
